import React, { ReactChild, ReactElement } from "react";

import BlockComponentSelector from "components/BlockComponentSelector/BlockComponentSelector";
import EditWrapper from "components/EditWrapper";
import EpiContent from "types/EpiContent";
import { addEditAttributes } from "utils/episerver";

import { Container } from "./ContentArea.styled";
import { ContentAreaProps } from "./ContentAreaProps";

const ContentArea = ({
    items,
    children,
    className = "",
    areaName = "Blocks",
    componentSelector,
    contentSizes,
}: ContentAreaProps): ReactElement => {
    const blockMapper = (item: EpiContent, index: number): ReactChild =>
        item && (
            <EditWrapper
                contentId={item.contentId}
                style={{ width: "100%" }}
                key={`ContentArea${item.contentId}${index}`}
            >
                <BlockComponentSelector
                    componentSelector={componentSelector}
                    content={item}
                    contentSizes={contentSizes}
                />
            </EditWrapper>
        );

    return (
        <>
            {children && children}
            <Container className={className} {...addEditAttributes(areaName)}>
                {items && items.map(blockMapper)}
            </Container>
        </>
    );
};

export default React.memo(ContentArea);
